/* eslint-disable class-methods-use-this */
export default class UserLoginAndRegistration {
    constructor() {
        this.$loginForm = null;
        this.$registrationForm = null;
    }

    initialize() {
        this.$loginForm = document.querySelector('.js_loginForm');
        this.$registrationForm = document.querySelector('.js_registrationForm');

        if (this.$loginForm) {
            this._setFieldsClasses(this.$loginForm);
            this.$loginForm.addEventListener('submit', (e) => this._submitLoginForm(e));
        }

        if (this.$registrationForm) {
            this._presetSelectFields(this.$registrationForm);
            this._setFieldsClasses(this.$registrationForm);
            this.$registrationForm.addEventListener('submit', (e) => this._submitRegistrationForm(e));
        }
    }

    _presetSelectFields($form) {
        // after submitting a form with errors,
        // the servers sends back a form populated with the previously selected values
        // but because we have a custom select input,
        // we need to show the selected option label in the .js_formSelectValue element

        const $formInputWrap = $form.querySelectorAll('.js_formInputWrap');

        if (!$formInputWrap.length) return;

        $formInputWrap.forEach((inputWrap) => {
            if (!inputWrap.classList.contains('has-value')) return;

            const $select = inputWrap.querySelector('select');

            if (!$select) return;

            const $selectValueElement = inputWrap.querySelector('.js_formSelectValue');
            const selectedOptionValue = $select.querySelector('option:checked')?.innerText || '';
            $selectValueElement.innerText = selectedOptionValue;
        });
    }

    _setFieldsClasses($form) {
        const $formInputWrap = $form.querySelectorAll('.js_formInputWrap');

        if (!$formInputWrap.length) return;

        $formInputWrap.forEach((inputWrap) => {
            const $input = inputWrap.querySelector('input');
            const $select = inputWrap.querySelector('select');
            const $inputRevealBtn = inputWrap.querySelector('.js_formInputRevealBtn');

            if ($input) {
                const toggleClass = () => {
                    if ($input.value) {
                        inputWrap.classList.add('has-value');
                    } else {
                        inputWrap.classList.remove('has-value');
                    }
                };

                $input.addEventListener('keyup', toggleClass);
                $input.addEventListener('blur', toggleClass);
            }

            // set "has-value" class on parent wrap element of input/select that has value
            if ($select) {
                $select.addEventListener('change', () => {
                    const $selectValueElement = inputWrap.querySelector('.js_formSelectValue');
                    const selectedOptionValue = $select.querySelector('option:checked')?.innerText;
                    if ($select.value) {
                        $selectValueElement.innerText = selectedOptionValue;
                        inputWrap.classList.add('has-value');
                    } else {
                        $selectValueElement.innerText = '';
                        inputWrap.classList.remove('has-value');
                    }
                });
            }

            // make password field visible be changing type to text
            if ($inputRevealBtn) {
                $inputRevealBtn.addEventListener('click', () => {
                    const $passwordField = document.getElementById('id_password');
                    $inputRevealBtn.classList.toggle('is-active');
                    const fieldType = $passwordField.type;
                    if ($passwordField) {
                        $passwordField.type = fieldType === 'password' ? 'text' : 'password';
                    }
                });
            }
        });
    }

    _submitLoginForm(e) {
        if (!this.$loginForm.classList.contains('js_isValidated', 'js_isValid')) {
            e.preventDefault();
            const $formFields = this.$loginForm.querySelectorAll('.js_formInputWrap > input');
            let formHasError = false;
            $formFields.forEach(($field) => {
                if (!$field.value) formHasError = true;
                $field.closest('.js_formInputWrap').classList.toggle('has-error', !$field.value);
            });

            if (!formHasError) {
                this.$loginForm.classList.add('js_isValidated', 'js_isValid');
                this.$loginForm.submit();
            }
        } else {
            this.$loginForm.classList.remove('js_isValidated', 'js_isValid');
        }
    }

    _submitRegistrationForm(e) {
        if (!this.$registrationForm.classList.contains('js_isValidated', 'js_isValid')) {
            e.preventDefault();
            const $formFields = this.$registrationForm.querySelectorAll('.js_formInputWrap > input, select');
            let formHasError = false;
            $formFields.forEach(($field) => {
                if (!$field.value) formHasError = true;
                $field.closest('.js_formInputWrap').classList.toggle('has-error', !$field.value);
            });

            if (!formHasError) {
                this.$registrationForm.classList.add('js_isValidated', 'js_isValid');
                this.$registrationForm.submit();
            }
        } else {
            this.$registrationForm.classList.remove('js_isValidated', 'js_isValid');
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    const userLogin = new UserLoginAndRegistration();
    userLogin.initialize();
});
